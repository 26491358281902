@import '@/styles/config';
@import '@/styles/mixins/box-shadow';


.container {
  padding: map-get($map: $spacing, $key: sitePadding);
  @include box-shadow();
  border-radius: map-get($map: $spacing, $key: borderRadius);

  display: grid;
  grid-template-areas:
    'image info action'
    'image data action'
    'image data action';
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  column-gap: 1rem;
  row-gap: 1rem;

  @include breakpoint(large, max) {
    grid-template-areas:
      'image info'
      'image data'
      'action action';
    grid-template-columns: auto 1fr;
  }

  @include breakpoint(small, max) {
    grid-template-areas:
      'image info'
      'image info'
      'data data'
      'action action';
    grid-template-columns: auto 1fr;
  }
}

.image {
  grid-area: image;
  position: relative;
  height: 160px;
  width: 160px;

  @include breakpoint(small, max) {
    height: 100px;
    width: 100px;
  }

  img {
    object-fit: cover;
    border-radius: map-get($map: $spacing, $key: borderRadius);
  }
}

.shelf {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.mainInfo {
  grid-area: info;

}

.data {
  grid-area: data;
}

.actionItems {
  align-self: center;
  grid-area: action;
  display: grid;
  grid-template-areas:
    'details';
  grid-template-columns: auto;
  grid-auto-rows: min-content;

  @include breakpoint(large, max) {
    grid-template-areas:
      'details';
    grid-template-columns: 1fr;
  }

  @include breakpoint(small, max) {
    grid-template-areas:
      'details';
    grid-template-columns: 1fr;
  }
}

.readMore {
  cursor: default;
}

.details {
  grid-area: details;
  margin-bottom: 1rem;
  min-width: 150px;

  @include breakpoint(large, max) {
    align-self: center;
    margin-bottom: 0;
    margin-top: 1rem;
  }
}