@import '../config';
@import '../mixins/typography';
@import '../mixins/scroll';

.book {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: map-get($map: $spacing, $key: sitePadding);

  &-container {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    background-color: map-get($map: $colors, $key: background);
    border-radius: map-get($map: $spacing, $key: borderRadius);
  }

  &-image {
    position: relative;
    width: 5rem;
    min-width: 5rem;
    border-radius: map-get($map: $spacing, $key: borderRadius);
    overflow: hidden;

    &-overlay {
      background-color: rgba(79, 172, 104, 0.5);
      position: absolute;
      content: '&nbsp;';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 2rem;
      }
    }
  }

  &-seller-books {
    margin-top: 2rem;

    &-title {
      text-align: center;
      margin-bottom: 1rem;
    }

    &-modal-view {
      @include breakpoint(large, min) {
        margin-left: -5rem;
        min-width: calc(100% + 10rem);
      }
    }
  }

  &-content {
    margin-left: 1rem;

    h2 {
      @include type($font: heading, $size: base);
      margin-bottom: 0.15rem;
    }

    h3 {
      @include type($font: copy, $size: tiny, $color: copyLight);
    }

    &-seller {
      padding: map-get($map: $spacing, $key: sitePadding) / 2 map-get($map: $spacing, $key: sitePadding);
      border: 1px solid map-get($map: $colors, $key: borderLight);
      border-top-left-radius: map-get($map: $spacing, $key: borderRadius);
      border-top-right-radius: map-get($map: $spacing, $key: borderRadius);
    }

    &-seller-info {
      display: flex;
      align-items: center;

      p {
        @include type($font: copy, $size: tiny);
        margin-bottom: 0.1rem;
      }

      h3 {
        @include type($font: heading, $size: small);
      }

      svg {
        min-width: 0.7rem;
        min-height: 0.7rem;
        width: 0.7rem;
        height: 0.7rem;
      }

      &-image {
        display: block;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 0.5rem;
      }
    }
  }
}

.see-more-btn {
  display: flex;
  align-items: center;
  min-width: 14rem;
}