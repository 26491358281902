@import "../config";
@import "../mixins/typography";

.grid-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-pagination-buttons {
  margin: 2rem auto;
  display: flex;

  @include breakpoint(large, min) {
    padding: 1rem 2rem;
  }
}

.direction-button {
  width: 10rem;

  &:first-of-type {
    margin-right: 0.5rem;
  }

  &:last-of-type {
    margin-left: 0.5rem;
  }
}

.number-button {
  margin: 0 0.5rem;
  width: 3.25rem;

  @include breakpoint(large, max) {
    display: none;
  }
}