@import '@/styles/config';
@import '@/styles/config/colors';

.successIcon {
  width: 72px;
  height: 72px;
}

.grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: 6fr 4fr;
  grid-template-areas:
    'left right';

  @include breakpoint(medium, max) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'right'
      'left';
  }
}

.leftSide {
  grid-area: left;
}

.rightSide {
  grid-area: right;
}

.orderDetailsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'shipping billing'
    'payment billing';

  @include breakpoint(small, max) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'shipping'
      'billing'
      'payment';
  }
}

.shippingAddress {
  grid-area: shipping;
}

.billingAddress {
  grid-area: billing;
}

.paymentMethod {
  grid-area: payment;
}

.appleDownload {
  width: 110px;
}

.googleDownload {
  width: 120px;
}

.rating {
  width: 100px;
}

.greenCallout {
  background-color: $success-100 !important;
}

.greenCalloutIcon {
  width: 42px;
  height: 42px;
  margin-right: 0.5rem;
}

.orderimage {
  width: 64px;
  height: 64px;
  position: relative;
  border-radius: $borderRadius;

  img {
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: $borderRadius;
  }
}

.orderImageOverlay {
  position: absolute;
  bottom: -6px;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calloutGrid {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-template-areas:
    'calloutLeft calloutRight';

  @include breakpoint(small, max) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'calloutLeft'
      'calloutRight';
  }
}

.calloutLeft {
  grid-area: calloutLeft;

  @include breakpoint(small, max) {
    margin-bottom: 1rem;
  }
}

.calloutRight {
  grid-area: calloutRight;
}

.appStoreDownloadGrid {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-template-rows: auto;
  gap: 0.5rem;
  align-items: center;
  grid-template-areas:
    'appleDownload googleDownload ratingContainer';


  @include breakpoint(small, max) {
    grid-template-columns: 110px 120px;
    grid-template-rows: auto;
    grid-template-areas:
      'appleDownload googleDownload'
      'ratingContainer ratingContainer';
  }
}

.appleDownload {
  grid-area: appleDownload;
}

.googleDownload {
  grid-area: googleDownload;
}

.ratingContainer {
  grid-area: ratingContainer;
}

.successHeading {
  @include breakpoint(small, max) {
    font-size: 1.5rem;
  }
}