@import '../../config';
@import '../../mixins/typography';
@import '../../mixins/link';

.title-attributes {
  &-option {
    &-container {
      margin-bottom: 2rem;
    }
    cursor: pointer;
    content: '';
    clear: both;
    display: table;
    margin-bottom: 1rem;

    h3 {
      @include type($size: small, $font: copyBold);
      margin-bottom: 0.5rem;
    }

    &-pill {
      @include type($size: tiny);
      float: left;
      margin-right: 0.5rem;
      background-color: map-get($map: $colors, $key: foreground);
      display: flex;
      align-items: baseline;
      border-radius: 1rem;
      padding: 0.5rem 1rem;
      border: 1px solid map-get($map: $colors, $key: lighterGrey);
      transition: all 0.25s;

      .title-attributes-option-pill-text {
        @include type($size: small, $font: copyBold);
        transition: all 0.25s;
      }

      &--active {
        border: 1px solid map-get($map: $colors, $key: primary);
      }

      &--disabled {
        cursor: default;
        background-color: map-get($map: $colors, $key: borderLight);
        outline: 1px solid map-get($map: $colors, $key: borderLight);

        .title-attributes-option-pill-text {
          color: map-get($map: $colors, $key: lightGrey);
        }
      }
    }
  }

  &-callout ~ &-callout {
    margin-top: 1rem;
    // opacity: .2;
  }
}
