@import "../config";

.spacer {
    height: map-get($map: $spacing, $key: sitePadding);

    &--xtraTiny {
        height: map-get($map: $spacing, $key: sitePadding) / 6;
    }

    &--tiny {
        height: map-get($map: $spacing, $key: sitePadding) / 4;
    }

    &--small {
        height: map-get($map: $spacing, $key: sitePadding) / 2;
    }

    &--base {
        height: map-get($map: $spacing, $key: sitePadding);
    }

    &--medium {
        height: map-get($map: $spacing, $key: sitePadding) * 1.25;
    }

    &--large {
        height: map-get($map: $spacing, $key: sitePadding) * 1.5;
    }

    &--jumbo {
        height: map-get($map: $spacing, $key: sitePadding) * 2;
    }
}