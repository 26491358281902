@import '../config/';
@import '../mixins/typography';
@import '../mixins/box-shadow';

@mixin input-reset() {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  input.hide-clear[type='search']::-webkit-search-decoration,
  input.hide-clear[type='search']::-webkit-search-cancel-button,
  input.hide-clear[type='search']::-webkit-search-results-button,
  input.hide-clear[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  input.hide-clear[type='search']::-ms-clear,
  input.hide-clear[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
}

@mixin input() {
  @include input-reset();
  @include type();
  padding: 0.75rem 1rem;
  border: 1px solid map-get($map: $colors, $key: lighterGrey);
  border-radius: map-get($map: $spacing, $key: borderRadius);

  &:focus-visible {
    border: 1px solid map-get($map: $colors, $key: borderLight);
  }
}

@mixin checkbox() {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  transition: all 0.25s;

  input {
    transition: all 0.25s;
    border: 1px solid map-get($map: $colors, $key: copy);
    border-radius: 0.25rem;
    margin: 0;
    min-width: 1.25rem;
    min-height: 1.25rem;
    margin-right: 0.75rem;
  }

  label {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
