@import '../../config';
@import "../../mixins/typography";
@import "../../mixins/link";
@import "../../mixins/box-shadow";



.related_author {

  &-title {
    @include heading();
    @include type($color: copyDark, $font: heading, $size: large);
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &-row {
    display: flex;
    align-items: left;
    flex-wrap: wrap;
    
    @include breakpoint(medium, max) {
    justify-content: left;}
  }

  &-tile {
      margin-right: 2rem;
      margin-bottom: 1.5rem;
  }
  

  &-pill {
    margin-top: .5rem;
    margin-right: .5rem;
  }

  &-admin {
    margin-left: 2rem;
    display: flex;
    align-items: center;
    height: 10rem;


  }

  &-input {
    width: 20rem;
    border-color: black;
  }

  &-addbutton {
    max-width: 4rem;
    max-height: 2rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-left: 1rem;
  }

  &-onClose{
    width: 1.5rem;
    height: 1.5rem;
    position: relative;
    top: 1rem;
    right: -7rem;
    z-index: 100;
    cursor: pointer;

    // background on hover
    &:hover {
      background-color: whitesmoke;
      @include box-shadow();
      color: black;
    }
  }


}



.author-img {
  img {
    object-fit: cover;
    height: 130px;
    width: 130px;
  }

  border-radius: 50%;
}
