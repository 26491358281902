@import "../config";
@import "../mixins/typography";

.seller {
  h2 {
    @include type($font: copyBold, $size: large);
    margin-bottom: 0.5rem;
  }

  h3 {
    @include type($color: copyLight);
    margin-bottom: 1rem;
  }

  p {
    @include copy();
  }

  button {
    margin: 1rem 0;
  }
}
