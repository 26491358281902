@import '@/styles/config';
@import '@/styles/mixins/_animate_pulse';

.container {
  width: 100%;
  background-color: $primary-100;
  @include animate-pulse;
  border-radius: $borderRadius;
}

.sm {
  height: 1rem;
}

.md {
  height: 1.5rem;
}

.lg {
  height: 2rem;
}