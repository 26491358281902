@import "../config";
@import "../mixins/typography";
@import "../mixins/box-shadow";

.tooltip-container {
    position: relative;
    display: inline-block;

    .tooltip-content {
        @include copy($font-size: tiny);
        @include box-shadow();
        position: absolute;
        background-color: map-get($map: $colors, $key: foreground);
        padding: 0.5rem;
        border-radius: map-get($map: $spacing, $key: borderRadius);
        z-index: 10;
        text-align: center;

        width: max-content;
        max-width: 8rem;

        &-top {
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            margin-bottom: 8px;
        }

        &-right {
            top: 50%;
            left: 100%;
            transform: translateY(-50%);
            margin-left: 8px;
        }

        &-bottom {
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 8px;
        }

        &-left {
            top: 50%;
            right: 100%;
            transform: translateY(-50%);
            margin-right: 8px;
        }


        @media (hover: none) {
            display: none;
        }
    }
}