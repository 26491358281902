@import '@/styles/config';

.imageContainer {
  position: relative;
  width: 100%;
}

.wishlistButton {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.breadcrumbContainer {
  @include breakpoint(large, max) {
    display: none;
  }
}